import { Link } from 'gatsby';
import Img from 'gatsby-image';
import React, { Fragment } from 'react';
import './PostBanner.scss';
import HFLogo from '-!svg-react-loader!./huggingface_logo.svg'
import ColabLogo from '-!svg-react-loader!./colabnotebook_logo.svg'

interface PostBannerProps {
    post: Frontmatter;
    timeToRead: string;
    topicsDetails: Record<string, TopicDetails>;
}

const PostBanner: React.FC<PostBannerProps> = ({
    post,
    timeToRead,
    topicsDetails
}: PostBannerProps) => {

    React.useEffect(() => {
        // console.log(post.author[0][0])
        const author_name = post.author.map((a) => a[0])
        console.log(author_name)
    }, [])
    return (
        <div id="post-banner" className="post-banner">
            <div className="post-banner__content">
                <div className="post-banner__content__details">
                    <h1>{post.title}</h1>

                    <div className="post-banner__content__details__post-details">
                        <div>
                            {post.author.map((a, i) => (
                                <Fragment key={a}>
                                    <a
                                        className="post-banner__content__details__post-details__author"
                                        href={a[1]}
                                        target={'_blank'}
                                    >
                                        {a[0] + ((i !== post.author.length - 1 && ', ') || '')}
                                        {/* {i === post.author.length - 1 && a[0]} */}
                                    </a>
                                    {/* {'s' && <span>{','}</span>} */}
                                </Fragment>
                            ))}
                        </div>
                        <span>{'on'}</span>
                        <span>{post.publishedAt}</span>
                        <span>{'·'}</span>
                        <span>
                            {timeToRead}
                            {' mins'}
                        </span>
                    </div>
                    <div className="post-banner__content__details__topics">
                        {/* Github */}
                        {post.github && <div>
                            <a href={post.github} target={'_blank'}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="40"
                                    height="40"
                                    fill="#333"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
                                </svg>
                            </a>
                        </div>}
                        {/* HuggingFace */}
                        {post.huggingface && <div className="header__item header__item__secondary">
                            <a href={post.huggingface} target={'_blank'}>
                                <HFLogo />
                            </a>
                        </div>}
                        {/* Notebook */}
                        {post.notebook && <div className="header__item header__item__topics">
                            <a href={post.notebook} target={'_blank'}>
                                <ColabLogo />
                            </a>
                        </div>}
                    </div>
                    <div className="post-banner__content__details__topics">
                        <a href={post.paper_link} target={'_blank'} className="post-banner__content__details__topics__tag_green">📃 {post.venue}</a>
                    </div>
                    <div className="post-banner__content__details__topics">
                        {post.tags.map((p) => (
                            <Link
                                // to={`/topics/${topicsDetails[p].url}`}
                                className="post-banner__content__details__topics__tag"
                                key={p}
                            >
                                {p}
                            </Link>
                        ))}
                    </div>
                </div>
                <div className="post-banner__content__featured-image">
                    <Img
                        fluid={post.image.childImageSharp.fluid}
                        alt={post.title}
                    ></Img>
                </div>
            </div>
        </div>
    );
};

export default PostBanner;
